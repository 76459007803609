#assignModal {
    height: 80vh;
}

.modal-content {
    height: 80vh;
}

#assigncancelbutton {
    left: 10px;
    position: absolute;
}

#policycontainer {
    height: 60vh;
    overflow-y: scroll;
}

.dropdown {
    z-index: 1;
}

.modal-body {
    overflow-y: scroll;
}