@import url('https://fonts.googleapis.com/css2?family=Orbit&display=swap');

#mainwrapper {
    padding-top: 2vh;
}

.assignInfoButton {
    margin-left: 20px;
    height: 20px;
    width: fit-content;
    font-size: 15px;
    border: 0;
    border-radius: 10px;
    box-shadow: 1px 1px 1px 1px gray;
}

.assignInfoButton:active {
    box-shadow: 0px 0px 0px 0px gray;
    transform: translateY(2px);
}

.assignButton {
    margin-left: 20px;
    height: 20px;
    width: fit-content;
    font-size: 15px;
    border: 0;
    border-radius: 10px;
    box-shadow: 1px 1px 1px 1px gray;
}

.assignButton:active {
    box-shadow: 0px 0px 0px 0px gray;
    transform: translateY(2px);
}

.dropdown {
    z-index: 1;
}

#responsive-navbar-nav {
    z-index: 555;
}

.bg-body-tertiary {
    z-index: 555;
}

#leadermaincontainer {
    padding: 0vh;
    display: flex;
    height: 100%;
    width: 100vw;
}

#leadermaincontents {
    padding: 1vh;
    width: 100vw;
}

.appliers {
    width: fit-content;
    display: inline-block;
}

.applierscontainer {
    width: fit-content;
}