#logincontainer {
    margin-top: 30vh;
    width: 45vw;
    min-width: 400px;
    border-radius: 30px;
    background-color: rgb(230, 230, 230);
    box-shadow: 3px 3px 3px 3px gray;
    padding-top: 5vh;
    padding-bottom: 5vh;
    overflow:hidden;
	height:auto;
}

#registercontainer{
    margin-top: 10vh;
    margin-bottom: 5vh;
    width: 70vw;
    border-radius: 30px;
    background-color: rgb(230, 230, 230);
    box-shadow: 3px 3px 3px 3px gray;
    padding-top: 5vh;
    padding-bottom: 5vh;
    overflow:hidden;
	height:auto;
}

#alertingmsg {
    color: red;
}

#gotoregister {
    color: black;
    text-decoration: underline;
}

#loadingbutton {
    font-size: x-large;
}