body {
    margin: 0;
    padding: 0;
    height: 100%;
}

html {
    margin: 0;
    padding: 0;
    height: 100%;
}

.App {
    height: 100%;
    padding-top: 80px;
}

#root {
    height: 100%;
}