#assigncontainer {
    margin-top: 20vh;
    width: 70vw;
    border-radius: 30px;
    background-color: rgb(230, 230, 230);
    box-shadow: 3px 3px 3px 3px gray;
    padding-top: 5vh;
    padding-bottom: 5vh;
    overflow:hidden;
	height:auto;
}

#alertingmsg {
    color: red;
}

#filename {
    color: blue;
}

#cancelupload {
    margin-bottom: 5px;
}

.success {
    color: green;
}

.failed {
    color: red;
}