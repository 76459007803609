@import url('https://fonts.googleapis.com/css2?family=Gothic+A1:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR&display=swap');
.bg-body-tertiary {
    background-color: #20a494 !important;
    height: 80px;
    width: 100%;
    top: 0;
    position: fixed;
}

nav {
    padding: 0 !important;
}

.navbar-brand {
    color: white;
    font-size: 30px;
    width: 200px;
    font-family: 'Gothic A1', sans-serif;
    margin-right: 12vw;
}

.nav-link {
    color: white;
    font-size: 20px;
    font-family: 'Noto Sans KR', sans-serif;
}

.navlink {
    text-decoration-line: none;
    color: white;
    transition: all 0.3s;
    margin-right: 200px;
}

.navuserlink {
    text-decoration-line: none;
    color: white;
    transition: all 0.3s;
}

.innernavlink {
    text-decoration-line: none;
    color: white;
    transition: all 0.3s;
    margin-right: 50px;
}

.navbar-collapse.collapse.show {
    background-color: #20a494;
    width: 100%;
    transition: all 0.3s;
    border-radius: 10px;
}

#haslogo {
    height: 80px;
}

#navclub {
    margin-left: 0px;
}


#navgroup {
    margin-right: 0 !important;
    align-items: left;
    justify-content: left;
    width: 80%;
}

navbar-collapse.collapse{
    width: 100%;
}

#logoutwrap {
    width: 20%;
}


#responsive-navbar-nav {
    padding: 0;
    margin: 0;
    border: 0;
}

#navlogout {
    font-size: 20px;
}